import React from "react"
const socials = require('../data/socials.json');

export default function FollowUs() {
    return (
        <div className="aside-component">

            <article className="heading-component">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">Kövess minket!</h5>
                </div>
            </article>


            <div className="group-sm group-flex">
                <a className="button-media button-media-facebook" href={socials.facebook} target={'_blank'} rel={'noreferrer'}>
                    <h4 className="button-media-title">Facebook</h4>
                    <p className="button-media-action">Like
                        <span className="icon material-icons-add_circle_outline icon-sm"/>
                    </p>
                    <span className="button-media-icon fa-facebook"/>
                </a>
                <a className="button-media button-media-instagram" href={socials.instagram} target={'_blank'} rel={'noreferrer'}>
                    <h4 className="button-media-title">Intragram</h4>
                    <p className="button-media-action">Kövess
                        <span className="icon material-icons-add_circle_outline icon-sm"/>
                    </p>
                    <span className="button-media-icon fa-instagram"/>
                </a>
            </div>
        </div>
    )
}

