import * as React from "react"
import {Carousel} from "react-bootstrap";
import {Link} from "gatsby";

export default function HighlightedNews(props) {

    return (
        <Carousel fade>
            {props.news.map(function(article,index){
                return article.isHighlighted && <Carousel.Item key={index}>
                    <div className={'overlay'}/>
                    <div className={'img'} style={{backgroundImage:'url('+article.thumbnail.url+')'}}></div>

                    <Carousel.Caption>
                        <h3>{article.title}</h3>
                        <p>
                            {article.previewText}
                        </p>

                        <Link className="button" to={`/hirek/${article.slug}`}>
                            Tovább
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
            })}
        </Carousel>
    )
}

