import * as React from "react"
import {Link} from "gatsby";

export default function BlogPostSquare({post}) {
    return (
        <div className="col-sm-6">
            <article className="post-carmen">
                <img src={post.thumbnail.url} alt={post.title} width="369" height="343"/>
                <div className="post-carmen-header">
                    {post.label && post.label.map(function(label,labelIndex){
                        return <div key={labelIndex} className="badge badge-primary">{label}</div>
                    })}
                </div>

                <div className="post-carmen-main">
                    <h4 className="post-carmen-title">
                        <Link to={`/hirek/${post.slug}`}>{post.title}</Link>
                    </h4>
                    <div className="post-carmen-meta">
                        <div className="post-carmen-time"><span className="icon mdi mdi-clock"/>
                            <time dateTime={post.publishedAt}>{post.publishedAt}</time>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    )
}

