import * as React from "react"
import {Link} from "gatsby";

export default function BlogPostBig({post}) {
    return (
        <div className="col-sm-12">
            <article className="post-gloria">
                <img src={post.thumbnail.url} alt="" width="769" height="429"/>
                <div className="post-gloria-main">
                    <h3 className="post-gloria-title">
                        <Link to={`/hirek/${post.slug}`}>{post.title}</Link>
                    </h3>
                    <div className="post-gloria-meta">
                        {post.label && post.label.map(function(label,labelIndex){
                            return <div key={labelIndex} className="badge badge-primary">{label}</div>
                        })}
                        <div className="post-gloria-time">
                            <span className="icon mdi mdi-clock"/>
                            <time dateTime={post.publishedAt}>{post.publishedAt}</time>
                        </div>
                    </div>
                    <div className="post-gloria-text">
                        <svg version="1.1" x="0px" y="0px" width="6.888px" height="4.68px" viewBox="0 0 6.888 4.68"
                             enableBackground="new 0 0 6.888 4.68">
                              <path
                                  d="M1.584,0h1.8L2.112,4.68H0L1.584,0z M5.112,0h1.776L5.64,4.68H3.528L5.112,0z"/>
                            </svg>
                        <p>{post.previewText}</p>
                    </div>
                    <Link className="button" to={`/hirek/${post.slug}`}>Tovább</Link>
                </div>
            </article>
        </div>
    )
}

