import * as React from "react"
import {renderRichText} from "gatsby-source-contentful/rich-text";

export default function NextEvent({events}) {
    const nextEvents = events;

    const filteredEvents = nextEvents.filter(function(event){
        return new Date(event.date) >= new Date()
    })

    return (
        <div className="aside-component">

            {filteredEvents.length !== 0 && <article className="heading-component">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">Következő eseményünk</h5>
                </div>
            </article>}


            {filteredEvents.map(function (nextEvent,index){
                return <div key={index} className="promo promo-classic mb-2">
                    <div className="promo-meta promo-classic-meta" style={{backgroundImage:'url('+nextEvent.image.url+')'}}>
                        <div className={'overlay'}/>
                        <div className={'content'}>
                            <p className="promo-title heading-3">{nextEvent.title}</p>
                            <div className="divider-middle divider-primary"/>
                            <time className="promo-time text-white" dateTime={nextEvent.date}>{nextEvent.date}</time>
                        </div>
                    </div>

                    <div className={'pt-3'}>
                        <p>{renderRichText(nextEvent.text)}</p>
                    </div>


                    {((nextEvent.homeTeam !== "null" && nextEvent.awayTeam !== "null") || nextEvent.homeTeamHiuz || nextEvent.awayTeamHiuz) &&
                    <div className="promo-main promo-classic-main">
                        <div className="promo-team">
                            <figure className="promo-team-figure promo-classic-team-figure">
                                <img src={nextEvent.homeTeamHiuz ? require('../../images/zempleni-hiuzok-logo.png').default : nextEvent.homeTeamImage.url} alt={nextEvent.homeTeam} height="62"/>
                            </figure>
                            <div className="promo-team-name">{nextEvent.homeTeamHiuz ? 'Zempléni Hiúzok' : nextEvent.homeTeam}</div>
                        </div>
                        <div className="promo-middle promo-classic-middle">VS</div>
                        <div className="promo-team">
                            <figure className="promo-team-figure promo-classic-team-figure">
                                <img src={nextEvent.awayTeamImage ? require('../../images/zempleni-hiuzok-logo.png').default : nextEvent.awayTeamImage.url} alt={nextEvent.awayTeam} height="62"/>
                            </figure>
                            <div className="promo-team-name">{nextEvent.awayTeamHiuz ? 'Zempléni Hiúzok' : nextEvent.awayTeam}</div>
                        </div>
                    </div>
                    }

                    <div className={'bg-gray-100 mt-3'}>
                        {nextEvent.addressIsOurIcerink ? 'Sátoraljaújhelyi jégcsarnok' : nextEvent.address}
                    </div>
                </div>
            })}
        </div>
    )
}

