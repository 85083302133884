import * as React from "react"

export default function IceRinkLive() {
    return (
        <>
            <article className="heading-component mb-1">
                <div className="heading-component-inner">
                    <h5 className="heading-component-title">Élőben a jégcsarnokból</h5>
                </div>
            </article>
            <iframe width="100%" height="435" src="https://www.youtube.com/embed/4ZJAIRjfuKY"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </>
    )
}

