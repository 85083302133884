import * as React from "react"
import Layout from "../components/layout";
import LatestNews from "../components/latest-news";
import FollowUs from "../components/follow-us";
import {Helmet} from "react-helmet";
import {graphql, Link} from "gatsby";
import BlogPostSquare from "../components/elements/blogPostSquare";
import NextEvent from "../components/elements/nextEvent";
import IceRinkLive from "../components/elements/iceRinkLive";
import Team from "../components/elements/team";
import axios from "axios";
import BlogPostBig from "../components/elements/blogPostBig";
import Header from "../components/header";
import HighlightedNews from "../components/elements/highlightedNews";
import Footer from "../components/footer";


const IndexPage = ({data}) => {

  const partners = data.allContentfulPartners.nodes;

  const blogPosts = data.allContentfulBlogPost.nodes;
  const events = data.allContentfulEvent.nodes;
  const teams = data.allContentfulTeam.nodes;
  const pages = data.allContentfulPages.nodes

  async function getCalendar(){
    const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
    const API = 'https://api.icehockey.hu/vbr/v1';

    const seasonId = 216;

    try {
      axios.get(API+'/publicCalendar', {
        params: {
          seasonId:seasonId,
        },
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': APIKEY,
        },
      }).then((response) =>{
        //console.log(response.data.data);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function getSeasons(){
    const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
    const API = 'https://api.icehockey.hu/vbr/v1';

    const seasonId = 216;

    try {
      axios.get(API+'/all-season-list', {
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': APIKEY,
        },
      }).then((response) =>{
        console.log(response.data.data);
      });
    } catch (error) {
      console.error(error);
    }
  }


  async function getGamesCalendar(){
    const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
    const API = 'https://api.icehockey.hu/vbr/v1';

    const seasonId = 216;

    try {
      axios.get(API+'/championship-list', {
        params: {
          seasonId:seasonId,
        },
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': APIKEY,
        },
      }).then((response) =>{
        //console.log(response.data.data);
      });
    } catch (error) {
      console.error(error);
    }
  }


  return (
      <Layout>
        <Helmet>
          <title>Zempléni Hiúzok</title>
        </Helmet>
        <Header pages={pages} teams={teams}/>
        <main>
          <div className={'highlighted-news'}>
            <HighlightedNews news={blogPosts}/>
          </div>

          <section className="section section-md bg-gray-100">
            <div className="container">
              <div className="row row-50">
                <div className="col-lg-8">
                  <div className="main-component">

                    <div className={'d-block d-lg-none py-5'}>
                      <NextEvent events={events}/>
                    </div>

                    <article className="heading-component">
                      <div className="heading-component-inner">
                        <h5 className="heading-component-title">Legutóbbi híreink</h5>
                      </div>
                    </article>

                    <div className="row row-30">
                      {blogPosts.map(function(post,index){
                        if(index === 2){
                          return <BlogPostBig post={post}/>
                        }else{
                          return index <=3 && <BlogPostSquare post={post}/>
                        }
                      })}
                    </div>
                  </div>

                  <div className={'main-component'}>
                    <article className="heading-component">
                      <div className="heading-component-inner">
                        <h5 className="heading-component-title">Csapataink</h5>
                      </div>
                    </article>
                    <div className="row row-30">
                      {teams.map(function(team,index){
                        return <div key={index} className="col-md-4">
                          <Team content={team}/>
                        </div>
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">

                  <aside className="aside-components">
                    <div className={'d-none d-lg-block'}>
                      <NextEvent events={events}/>
                    </div>
                    <LatestNews news={blogPosts}/>
                    <FollowUs/>

                    <IceRinkLive/>
                  </aside>

                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer partners={partners}/>
      </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query getNews {
      allContentfulBlogPost(sort: {fields: publishedAt, order: DESC},limit: 9) {
        nodes {
          label
          slug
          title
          text {
            raw
          }
          publishedAt
          previewText
          isHighlighted
          thumbnail {
            url
          }
        }
        group(field: label) {
          nodes {
            label
          }
        }
      }
      allContentfulTeam(sort: {fields: title, order: ASC}) {
        nodes {
        id
        label
        title
        championshipId
        teamId
        image {
          url
        }
        thumbnail {
          url
        }
        }
      }
      allContentfulPartners {
        nodes {
          title
          logo {
            url
          }
        }
      }
      allContentfulPages {
        nodes {
          text {
            raw
          }
          title
          slug
        }
      }
      allContentfulEvent {
        nodes {
          address
          addressIsOurIcerink
          date(formatString: "YYYY-MM-DD HH:mm:ss")
          title
          text {
            raw
          }
          homeTeam
          homeTeamHiuz
          image {
            url
          }
          awayTeamHiuz
          awayTeam
          homeTeamImage {
            url
          }
          awayTeamImage {
            url
          }
        }
      }
    }
`

